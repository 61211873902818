import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import last from 'lodash/last'
import some from 'lodash/some'
import moment from 'moment'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { readHandbookSection } from '../actions'
import { tocBySectionId } from '../navigation/toc'
import { initialState } from '../state'
import { isTranslation, renderTranslation, translate } from '../translate'
import {
  AnnotationModal,
  Header,
  Main,
  getSectionIds,
  useAnnotations,
} from './util'
const { handbook } = initialState

const useViewAnnotations = () => {
  const props = useAnnotations()
  const { annotationsBySectionId, onClickSectionId, sectionId } = props

  const sectionIds = useMemo(
    () =>
      getSectionIds(annotationsBySectionId).filter((id) =>
        some(annotationsBySectionId[id], (s) => !s.readAt),
      ),
    [annotationsBySectionId],
  )

  const onClickPrevious = useCallback(() => {
    const index = sectionIds.indexOf(sectionId)
    const nextSectionId = sectionIds[index - 1]
    onClickSectionId(nextSectionId)
  }, [sectionId, sectionIds])

  const onClickNext = useCallback(() => {
    const index = sectionIds.indexOf(sectionId)
    const nextSectionId = sectionIds[index + 1]
    onClickSectionId(nextSectionId)
  }, [sectionId, sectionIds])

  const onClickRead = useCallback(() => {
    // Go to the next section
    const index = sectionIds.indexOf(sectionId)
    const nextSectionId = sectionIds[index + 1] || sectionIds[index - 1]
    onClickSectionId(nextSectionId)

    // Mark the section as read
    readHandbookSection(sectionId).catch(logUIError)
  }, [sectionId, sectionIds])

  return {
    ...props,
    onClickPrevious,
    onClickNext,
    onClickRead,
    sectionIds,
  }
}

export const ViewAnnotations = (props) => {
  const viewProps = useViewAnnotations()
  const Component = viewProps.sectionId ? SectionAnnotations : Introduction
  return <Component {...props} {...viewProps} />
}

const Introduction = ({ onClickNext, sectionIds }) => {
  const changeCount = sectionIds.length
  const changeCountText = pluralize('Change', changeCount)
  const changeCountVariable = changeCount === 1 ? 'One' : '{{changeCount}}'
  return (
    <AnnotationModal id='annotation'>
      <Header className='-desktop'>
        <h5 className='tw-text-red-700'>
          {translate(
            `${changeCountVariable} ${changeCountText} Since You Last Viewed…`,
            { changeCount },
          )}
        </h5>
      </Header>

      <main className='-px -desktop'>
        <p className='tw-text-gray-600 tw-mb-0'>
          {renderTranslation(
            `To see what's changed since you last viewed the {{handbookName}}, look for a ✦ in the table of contents or use the navigation below.`,
            {
              handbookName: handbook.name,
            },
          )}
        </p>
      </main>

      <footer>
        <button
          type='button'
          className='btn btn-red'
          name='next'
          onClick={onClickNext}
        >
          <span className='-desktop'>{translate('Go to First Change')}</span>
          <span className='-mobile'>
            {translate(
              `${changeCountVariable} ${changeCountText} Since You Last Viewed`,
              { changeCount },
            )}
          </span>
          <FontAwesomeIcon className='tw-ml-2' icon='arrow-right' />
        </button>
      </footer>
    </AnnotationModal>
  )
}

const SectionAnnotations = ({
  annotationsBySectionId,
  onClickNext,
  onClickPrevious,
  onClickRead,
  sectionId,
  sectionIds,
  sizeClassName,
  ...props
}) => {
  const annotations = annotationsBySectionId[sectionId]
  const tocItem = tocBySectionId[sectionId]
  if (!annotations || !tocItem) return null

  return (
    <AnnotationModal id='annotation' className={sizeClassName}>
      <Header {...props}>
        <h5 className='tw-text-red-700'>{tocItem.title}</h5>
      </Header>

      <Main {...props} key={sectionId}>
        <div className='tw-flex tw-flex-col-reverse -px'>
          {annotations.map((annotation) => (
            <div className='tw-mb-4' key={annotation.version}>
              <h5 className='tw-mt-0 tw-mb-1'>
                {moment(annotation.createdAt).format('YYYY.M.DD')}
              </h5>
              <p className='annotation-content tw-my-0'>
                {annotation.annotation}
              </p>
            </div>
          ))}
        </div>
      </Main>

      <footer>
        <div className='tw-flex tw-items-center' style={{ flex: 1 }}>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClickPrevious}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </button>

          <If condition={sectionId !== last(sectionIds)}>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClickNext}
            >
              <FontAwesomeIcon icon='arrow-right' />
            </button>
          </If>
        </div>

        <button
          type='button'
          className='btn btn-red'
          name='dismiss'
          onClick={onClickRead}
        >
          {isTranslation('Dismiss') ? translate('Dismiss') : 'Got It, Dismiss'}
        </button>
      </footer>
    </AnnotationModal>
  )
}
