import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { setSignOpen } from '../actions'
import { tocBySectionId } from '../navigation/toc'
import { renderTranslation, translate } from '../translate'
import {
  AnnotationModal,
  Header,
  Main,
  getSectionIds,
  useAnnotations,
} from './util'

const useSignAnnotations = () => {
  const props = useAnnotations()
  const { sectionId } = props
  const signatures = useStore('handbook.signatures')
  const [lastSectionId, setLastSectionId] = useState()
  const [showIntroduction, setShowIntroduction] = useState(true)

  useEffect(() => {
    if (sectionId) {
      setLastSectionId(sectionId)
      setShowIntroduction(false)
    }
  }, [sectionId])

  return {
    ...props,
    lastSectionId,
    setLastSectionId,
    setShowIntroduction,
    showIntroduction,
    signatures,
  }
}

export const SignAnnotations = (props) => {
  const signProps = useSignAnnotations()
  const Component = signProps.showIntroduction
    ? Introduction
    : SectionAnnotations
  return <Component {...props} {...signProps} />
}

const Introduction = ({ setShowIntroduction, signatures }) => (
  <AnnotationModal id='annotation'>
    <Header className='-desktop'>
      <h5 className='tw-text-red-700 tw-mt-0'>
        {translate(`Since You Last ${signatures.length ? 'Signed' : 'Viewed'}`)}
        …
      </h5>
    </Header>

    <main className='-px -desktop'>
      <p className='tw-text-gray-600'>
        {renderTranslation(
          `To see what's changed since you last ${
            signatures.length ? 'signed' : 'viewed'
          }, look for a ✦ in the table of contents or use the review menu below.`,
        )}
      </p>
      <p className='tw-text-gray-600 disclaimer tw-mb-0'>
        {translate(
          'Note: There may be changes not presented here that were deemed unnecessary to highlight.',
        )}
      </p>
    </main>

    <footer>
      <button
        type='button'
        className='btn btn-secondary tw-flex tw-items-center tw-justify-center text-left'
        onClick={() => setShowIntroduction(false)}
        style={{ flex: 2 }}
      >
        <FontAwesomeIcon className='tw-mr-2' icon='bars' />
        {translate('Review Changes')}
      </button>

      <button
        type='button'
        className='btn btn-red'
        onClick={() => setSignOpen(true)}
        style={{ flex: 1 }}
      >
        {translate('Sign')}
      </button>
    </footer>
  </AnnotationModal>
)

const SectionAnnotations = ({
  annotationsBySectionId,
  lastSectionId,
  onClickSectionId,
  sectionId,
  sizeClassName,
  ...props
}) => {
  const sectionIds = getSectionIds(annotationsBySectionId)
  const currSectionId = sectionId || lastSectionId
  const annotations = annotationsBySectionId[currSectionId]
  const tocItem = tocBySectionId[currSectionId]
  return (
    <div id='annotation' className={sizeClassName}>
      <AnnotationPage in={!sectionId} className='exit-left'>
        <AnnotationModal>
          <Header {...props}>
            <h5>{translate('Sections That Changed:')}</h5>
          </Header>

          <Main {...props}>
            {sectionIds.map((sectionId) => {
              const tocItem = tocBySectionId[sectionId]
              return (
                <button
                  key={sectionId}
                  type='button'
                  className='annotation-section'
                  onClick={() => onClickSectionId(sectionId)}
                >
                  <span className='tw-mr-2' />
                  <span style={{ flex: 1 }}>{tocItem.title}</span>
                  <FontAwesomeIcon className='tw-ml-2' icon='angle-right' />
                </button>
              )
            })}
          </Main>

          <footer>
            <button
              type='button'
              className='btn btn-red btn-block'
              onClick={() => setSignOpen(true)}
            >
              <FontAwesomeIcon className='tw-mr-2' icon='check' />
              {translate('Sign')}
            </button>
          </footer>
        </AnnotationModal>
      </AnnotationPage>

      <AnnotationPage in={!!sectionId} className='exit-right'>
        <AnnotationModal className='tw-flex-row'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => onClickSectionId(undefined)}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </button>

          <div className='tw-flex tw-flex-col' style={{ flex: 1 }}>
            <Header {...props}>
              <h5 className='tw-text-red-700'>
                {tocItem ? tocItem.title : '(no title)'}
              </h5>
            </Header>

            <Main {...props} key={currSectionId}>
              <div className='tw-flex tw-flex-col-reverse -px'>
                <If condition={annotations}>
                  {annotations.map((annotation) => (
                    <div className='tw-mb-4' key={annotation.version}>
                      <h5 className='tw-mt-0 tw-mb-1'>
                        {moment(annotation.createdAt).format('YYYY.M.DD')}
                      </h5>
                      <p className='annotation-content tw-my-0'>
                        {annotation.annotation}
                      </p>
                    </div>
                  ))}
                </If>
              </div>
            </Main>
          </div>
        </AnnotationModal>
      </AnnotationPage>
    </div>
  )
}

const AnnotationPage = ({ children, className, ...props }) => (
  <Transition {...props} timeout={250}>
    {(state) => {
      return (
        <div className={classnames('annotation-page', className, state)}>
          {children}
        </div>
      )
    }}
  </Transition>
)
