import $ from 'jquery'
import { initialState } from '../state'
import { isTranslation, translate } from '../translate'
const { branding } = initialState.handbook

function translateButtonText(buttonText: string, translateButtonText: string) {
  const useTranslation = isTranslation(translateButtonText)
  return useTranslation ? translate(translateButtonText) : buttonText
}

export const lessButtonText = translateButtonText(
  branding.lessButtonText,
  'Collapse',
)
export const moreButtonText = translateButtonText(
  branding.moreButtonText,
  'Read More',
)

export const readMoreEls =
  document.querySelectorAll<HTMLDivElement>('.read-more')
for (const readMoreEl of readMoreEls) {
  // Create the toggleEl
  const toggleEl = document.createElement('div')
  toggleEl.className = 'read-more-toggle'
  readMoreEl.prepend(toggleEl)

  // Hide the content el
  const contentEl =
    readMoreEl.querySelector<HTMLDivElement>('.read-more-content')
  contentEl.style.height = '0px'

  const openButtonEl = document.createElement('button')
  openButtonEl.className = 'btn-toggle btn-toggle-open brand-primary-background'
  openButtonEl.textContent = moreButtonText
  openButtonEl.addEventListener('click', () => {
    contentEl.style.height = null
    const height = contentEl.offsetHeight
    contentEl.style.height = '0px'
    toggleEl.classList.add('animate')
    setTimeout(() => {
      toggleEl.classList.add('open')
      contentEl.style.height = height + 'px'
    })
  })
  toggleEl.append(openButtonEl)

  const closeButtonEl = document.createElement('button')
  closeButtonEl.className =
    'btn-toggle btn-toggle-close brand-primary-background'
  closeButtonEl.textContent = lessButtonText
  closeButtonEl.addEventListener('click', () => {
    const height = contentEl.offsetHeight
    contentEl.style.height = height + 'px'
    toggleEl.classList.add('animate')
    setTimeout(() => {
      toggleEl.classList.remove('open')
      contentEl.style.height = '0px'
    })
  })
  toggleEl.append(closeButtonEl)

  // After transition complete
  $(readMoreEl)
    .on(
      'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
      () => {
        const isOpen = toggleEl.classList.contains('open')
        contentEl.style.height = isOpen ? null : '0px'
        toggleEl.classList.remove('animate')
      },
    )
    .on('open', () => {
      toggleEl.classList.add('open')
      contentEl.style.height = null
    })
}

// Open the read-more for this section (if any)
export const openReadMore = (sectionId: number) => {
  const node = document.querySelector(
    `section[data-id="${sectionId}"] .read-more`,
  )
  if (node) $(node).trigger('open')
}
