import { ScrollContainer } from '@blissbook/ui/lib/scroll'
import { history } from '@blissbook/ui/util/history'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useHandbookAnnotationsBySectionId } from '../hooks'
import {
  getSectionId,
  setSectionId as setTocSectionId,
} from '../navigation/toc'
import { initialState } from '../state'
import { openReadMore } from '../widgets/read-more'
const { toc } = initialState.handbook
const sectionIds = toc.map((item) => item.sectionId)

export const getSectionIds = (annotationsBySectionId) =>
  sectionIds.filter((id) => annotationsBySectionId[id])

export const useAnnotations = () => {
  const annotationsBySectionId = useHandbookAnnotationsBySectionId()
  const [isMaximized, setMaximized] = useState(false)
  const [isMinimized, setMinimized] = useState(false)
  const [sectionId, setSectionId] = useState()

  const sizeClassName = classnames({
    '-maximized': isMaximized,
    '-minimized': isMinimized,
  })

  const onMaximize = useCallback(() => {
    setMaximized(!isMaximized)
    setMinimized(false)
  }, [isMaximized])

  const onMinimize = useCallback(() => {
    setMaximized(false)
    setMinimized(!isMinimized)
  }, [isMinimized])

  const onChangeSectionId = useCallback((sectionId) => {
    setMinimized(false)
    setSectionId(sectionId)
    if (sectionId) openReadMore(sectionId)
  }, [])

  // Listen to URL sectionId changes
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const sectionId = getSectionId(location)
      if (annotationsBySectionId[sectionId]) {
        onChangeSectionId(sectionId)
      } else {
        onChangeSectionId()
      }
    })

    return () => {
      unlisten()
    }
  }, [])

  const onClickSectionId = useCallback((sectionId) => {
    onChangeSectionId(sectionId)
    if (sectionId) setTocSectionId(sectionId)
  }, [])

  return {
    annotationsBySectionId,
    isMaximized,
    isMinimized,
    onClickSectionId,
    onMaximize,
    onMinimize,
    sectionId,
    setMaximized,
    setMinimized,
    sizeClassName,
  }
}

export const Header = ({
  children,
  className,
  isMaximized,
  isMinimized,
  onMaximize,
  onMinimize,
  style,
}) => (
  <header
    className={classnames(
      'tw-flex tw-items-center tw-justify-between',
      className,
    )}
    style={style}
  >
    {children}
    <div className='tw-flex tw-items-center -mobile'>
      <HeaderButton
        icon={['far', 'window-minimize']}
        isActive={isMinimized}
        onClick={onMinimize}
        title='Minimize'
      />
      <HeaderButton
        icon={['far', 'window-maximize']}
        isActive={isMaximized}
        onClick={onMaximize}
        title='Maximize'
      />
    </div>
  </header>
)

const HeaderButton = ({ className, icon, isActive, ...props }) => (
  <button
    {...props}
    type='button'
    className={classnames('btn-icon', { '-active': isActive }, className)}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)

export const Main = ({
  children,
  className,
  isMaximized,
  isMinimized,
  style,
}) => (
  <main
    className={className}
    style={{
      ...style,
      display: isMinimized ? 'none' : undefined,
      flex: isMaximized ? 1 : undefined,
      minHeight: 0,
    }}
  >
    <ScrollContainer
      children={children}
      height={isMaximized ? '100%' : undefined}
      maxHeight={isMaximized ? undefined : 200}
      showScrollText
    />
  </main>
)

export const AnnotationModal = ({ className, ...props }) => (
  <div
    {...props}
    className={classnames('modal-dialog -annotation', className)}
  />
)
