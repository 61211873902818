import './index.scss'
import { useStore } from '@blissbook/ui/util/store'
import React from 'react'
import { useHandbookAnnotationsBySectionId } from '../hooks'
import { SignAnnotations } from './sign'
import { ViewAnnotations } from './view'

export const Annotations = (props) => {
  const annotationsBySectionId = useHandbookAnnotationsBySectionId()
  const isSignatureRequired = useStore('isSignatureRequired')
  const hasAnnotations = Object.keys(annotationsBySectionId).length > 0
  const Component = isSignatureRequired ? SignAnnotations : ViewAnnotations
  return (
    <If condition={hasAnnotations}>
      <Component {...props} />
    </If>
  )
}
