import { history } from '@blissbook/ui/util/history'
import { Store } from '@blissbook/ui/util/store'
import type { Location } from 'history'
import produce from 'immer'
import filter from 'lodash/filter'
import qs from 'query-string'
import { type BlissbookState, initialState, isLive, isPreview } from './state'

function onChangeLocation(
  draft: BlissbookState,
  {
    isInitialState = false,
    location,
  }: {
    isInitialState?: boolean
    location: Location
  },
) {
  // Set the preview mode (if different)
  const previewMode = qs.parse(location.search).mode
  const isPreviewModeChange =
    isInitialState || previewMode !== draft.previewMode
  if (previewMode !== draft.previewMode) {
    draft.previewMode = previewMode
  }

  // Update based on signing or not
  const isSignatureRequired =
    !!draft.handbook.signatureRoundId ||
    (isPreview && previewMode === 'signing')
  if (isSignatureRequired !== draft.isSignatureRequired) {
    const { annotations, signatures } = initialState.handbook
    const hasSigned = signatures ? signatures.length > 0 : false
    draft.isSignatureRequired = isSignatureRequired
    draft.handbook.annotations = filter(
      annotations,
      (a) => !a.readAt || (isSignatureRequired && hasSigned),
    )
  }

  // Force sign open, if can sign
  const { forceSign } = draft.handbook.acknowledgementForm
  const allowForceSign = isLive || (isPreview && isPreviewModeChange)
  if (isSignatureRequired && forceSign && allowForceSign) {
    draft.isSignOpen = true
  }
}

// Set the store's initial state by the location
const storeInitialState = produce(initialState, (draft: BlissbookState) => {
  onChangeLocation(draft, {
    isInitialState: true,
    location: history.location,
  })
})

// Create the store
export const store = Store.create(storeInitialState)

// Listen to the history
history.listen((location) => {
  store.produce((draft) => {
    onChangeLocation(draft, { location })
  })
})
