import { gql } from '@apollo/client'
import type { MutationSignHandbookSessionArgs } from '@blissbook/application/graph'
import HandbookSignature from '@blissbook/common/handbook/signature'
import { client } from '@blissbook/ui/util/apollo'

const READ_HANDBOOK_SECTION = gql`
  mutation readHandbookSection($sectionId: Int!, $versions: [Int!]) {
    readHandbookSection(sectionId: $sectionId, versions: $versions) {
      createdAt
    }
  }
`

export const readHandbookSection = async (
  sectionId: number,
  versions: number[],
) => {
  const { data } = await client.mutate({
    mutation: READ_HANDBOOK_SECTION,
    variables: { sectionId, versions },
  })

  return data.readHandbookSection
}

const SIGN_HANDBOOK_SESSION = gql`
  mutation signHandbookSession(
    $handbookId: Int!
    $handbookSessionId: Int!
    $input: SignHandbookSessionInput!
    $signatureRoundId: Int!
  ) {
    signature: signHandbookSession(
      handbookId: $handbookId
      handbookSessionId: $handbookSessionId
      input: $input
      signatureRoundId: $signatureRoundId
    ) {
      id
      handbookVersion
      signature
      signedAt
      createdAt
    }
  }
`

export async function signHandbookSession(
  variables: MutationSignHandbookSessionArgs,
) {
  const { data } = await client.mutate({
    mutation: SIGN_HANDBOOK_SESSION,
    variables,
  })

  return HandbookSignature.fromJSON(data.signature)
}
