import { buildRootExpressionFromOperand } from '@blissbook/lib/expression'
import {
  Dropdown,
  GroupBadges,
  InfiniteContextLoading,
  InfiniteContextWaypoint,
  SearchInput,
  useInfinite,
  wrapInfinite,
} from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import pluralize from 'pluralize'
import qs from 'query-string'
import React, { Fragment, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { setPreviewMode } from '../actions'
import { initialState } from '../state'
import { getPeople, usePreviewQuery } from './queries'
const { handbook, person } = initialState
const { canPreview, isPublic, signatureRoundId } = handbook

const modes = [
  {
    label: 'viewing',
    value: undefined,
  },
  {
    label: 'signing',
    value: 'signing',
  },
]

const filterGroups = (groups, search) => {
  if (!groups || !search || search.length < 2) return []
  search = search.toLowerCase()
  return groups
    .filter((g) => !g.archived)
    .filter((g) => g.peopleCount > 0)
    .filter((g) => g.name.toLowerCase().includes(search))
}

const mapGroupToChip = (group) => ({
  groupId: group.id,
  icon: 'user-friends',
  key: group.uid,
  label: group.name,
  object: group,
  tooltip: {
    content: `Remove Group: ${group.name}`,
  },
})

const useFilterGroups = (groups, search) =>
  useMemo(() => filterGroups(groups, search), [groups, search])

const getModeFromValue = (value) =>
  modes.find((m) => m.value === value) || modes[0]

const getPreviewName = (person, session) =>
  person.id === session.personId ? 'You' : person.fullName

const getPreviewUrl = (location, personId) => {
  const { hash, pathname, search } = location
  const query = qs.parse(search)
  query.person = personId
  return `${pathname}?${qs.stringify(query)}${hash}`
}

const HandbookName = () => (
  <span className='name' title={handbook.name}>
    {handbook.name}
  </span>
)

const PersonFragment = ({ groupsById, person }) => {
  const session = useStore('session')
  const groupIds = person.groupIds || []
  const groups = groupIds.map((id) => groupsById[id]).filter(Boolean)
  const name = getPreviewName(person, session)
  return (
    <Fragment>
      <div className='ellipsis tw-mr-2' css={{ width: 200 }} title={name}>
        <span className='tw-text-gray-500 tw-mr-1'>to</span>
        {name}
      </div>

      <If condition={groups.length}>
        <GroupBadges groups={groups} />
      </If>
    </Fragment>
  )
}

const PersonDropdownItem = ({ groupsById, person }) => {
  const location = useLocation()
  return (
    <a
      href={getPreviewUrl(location, person.id)}
      className='dropdown-item tw-flex tw-items-center tw-justify-between'
    >
      <PersonFragment groupsById={groupsById} person={person} />
    </a>
  )
}

const InfinitePeopleDropdownItems = wrapInfinite(
  ({ groupsById, rows: people }) => (
    <Fragment>
      {people.map((person) => (
        <PersonDropdownItem
          key={person.id}
          groupsById={groupsById}
          person={person}
        />
      ))}

      <InfiniteContextLoading className='tw-my-1'>
        Loading People
      </InfiniteContextLoading>

      <InfiniteContextWaypoint />
    </Fragment>
  ),
)

const InfinitePeopleDropdown = ({ className, ...props }) => {
  const { groups, handbook } = usePreviewQuery(initialState.handbook.id)
  const location = useLocation()
  const [groupIds, setGroupIds] = useState([])
  const [search, setSearch] = useState(null)
  const availableGroups = useFilterGroups(groups, search)

  const groupsById = useMemo(() => keyBy(groups, 'id'), [groups])

  const chips = useMemo(
    () => groupIds.map((id) => groupsById[id]).map(mapGroupToChip),
    [groupIds, groupsById],
  )

  const expression = groupIds.length
    ? buildRootExpressionFromOperand({
        groups: { isEvery: true, ids: groupIds },
      })
    : undefined

  const pageSize = 50
  const infinite = useInfinite(
    (lastPerson) =>
      getPeople({
        after: get(lastPerson, 'sortKey'),
        filter: { expression },
        limit: pageSize,
        order: {
          field: 'fullName',
          direction: 'asc',
        },
        search,
      }),
    pageSize,
    [groupIds, search],
  )

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        className={cx('tw-flex tw-items-center btn btn-input', className)}
      >
        <Choose>
          <When condition={isPublic}>If you're viewing the public version</When>
          <When condition={!person}>If you had access to all content</When>
          <Otherwise>
            <div
              className='tw-flex tw-items-center tw-justify-between tw-text-left'
              css={{ flex: 1, minWidth: 0 }}
            >
              <PersonFragment groupsById={groupsById} person={person} />
            </div>
          </Otherwise>
        </Choose>
      </Dropdown.ToggleButton>

      <Dropdown.Menu className='dropdown-menu-compact' sameWidth>
        <If condition={isPublic || person}>
          <a href={getPreviewUrl(location)} className='dropdown-item'>
            If you had access to all content
          </a>
        </If>

        <If condition={handbook}>
          <If condition={handbook.public && !isPublic}>
            <a
              href={getPreviewUrl(location, 'public')}
              className='dropdown-item'
            >
              If you're viewing the public version
            </a>
          </If>

          <If condition={handbook.hasAudience}>
            <SearchInput
              autoFocus
              chips={chips}
              className='dropdown-item-text'
              infoTooltip='Type a name or group'
              key={groupIds.length}
              placeholder='Filter...'
              value={search}
              onChangeChips={(chips) => {
                const groupIds = chips.map((chip) => chip.groupId)
                setGroupIds(groupIds)
              }}
              onChangeValue={setSearch}
            />

            <div
              css={{
                maxHeight: 240,
                overflow: 'auto',
                overscrollBehavior: 'none',
              }}
            >
              <If condition={groupIds.length < 3}>
                {availableGroups.map((group) => (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: requires some work
                  <div
                    className='tw-flex tw-items-center tw-justify-between dropdown-item'
                    key={group.id}
                    onClick={(event) => {
                      event.stopPropagation()
                      setGroupIds([...groupIds, group.id])
                      setSearch(null)
                    }}
                  >
                    <div className='tw-flex tw-items-center'>
                      <FontAwesomeIcon
                        className='dropdown-item-icon tw-mr-2'
                        icon={['far', 'user-friends']}
                      />
                      {group.name}
                    </div>
                    <div className='tw-text-gray-500'>
                      {pluralize('person', group.peopleCount, true)}
                    </div>
                  </div>
                ))}
              </If>

              <InfinitePeopleDropdownItems
                groupsById={groupsById}
                infinite={infinite}
              />
            </div>
          </If>
        </If>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

export const PreviewHeader = () => {
  const previewMode = useStore('previewMode')
  const mode = getModeFromValue(previewMode)
  return (
    <Fragment>
      <Choose>
        <When condition={isPublic}>
          <div>
            This is what viewing the <HandbookName /> looks like
          </div>
        </When>
        <When condition={signatureRoundId}>
          <div>
            This is what <strong>signing</strong> the <HandbookName /> looks
            like
          </div>
        </When>
        <Otherwise>
          <div>This is what</div>

          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-mx-2'>
              {mode.label}
            </Dropdown.ToggleButton>

            <Dropdown.Menu>
              {modes.map((mode, index) => (
                <button
                  key={index}
                  type='button'
                  className='dropdown-item'
                  onClick={() => setPreviewMode(mode.value)}
                >
                  {mode.label}
                </button>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <div>
            the <HandbookName /> looks like
          </div>
        </Otherwise>
      </Choose>

      <Choose>
        <When condition={canPreview}>
          <div>:</div>
          <InfinitePeopleDropdown
            className='tw-ml-2'
            css={{ minWidth: 345, width: '35%' }}
          />
        </When>
        <When condition={person}>
          <div>
            &nbsp;to <strong>{person.fullName}</strong>
          </div>
        </When>
        <When condition={isPublic}>
          <div>
            &nbsp;if you're viewing the <strong>public version</strong>
          </div>
        </When>
        <Otherwise>
          <div>
            &nbsp;if you had access to <strong>all content</strong>
          </div>
        </Otherwise>
      </Choose>
    </Fragment>
  )
}
